import baseApi from "@/helpers/baseApi";
import { setAuthToken } from "@/helpers/setAuthToken";
import { translateLang } from "@/plugins/i18n.js";
import { detectedBridgePlatform, getMe as getMeMobile } from "@/helpers/bridge";
import store from "@/store/index";

export async function authViaBridge(data) {
  try {
    delete baseApi.defaults.headers['Authorization'];
    const token = await store.getters['auth/getToken'];
    if (token) {
      return token;
    }

    let res = await baseApi.post('api/v2/user/create/',
      data,
    );

    if (res?.data?.auth_token){
      store.dispatch('auth/login', res?.data?.auth_token);
      return res.data.auth_token;
    } else {
      // TODO report event via sentry
      throw Error(translateLang('app-loader_error_description_no-post-token'));
    }
  } catch (err){
    return addAuthError(err);
  }
}

async function addAuthError(error){
  let errorCode = error?.response?.status;

  const finalErrorText = errorCode
    ? errorCode + ': ' + (error?.response?.data?.detail || error)
    : error?.response?.data?.detail || error;

  throw Error(finalErrorText);
}

async function getMe(){
  let meData;
  if (detectedBridgePlatform){
    meData = await getMeMobile();
  } else {
    try {
      meData = JSON.parse(process.env.VUE_APP_ME_DATA);
    } catch {
      throw Error(".env.development.local.example -> .env.development.local");
    }
  }
  store.dispatch('user/setOrtaMeData', meData);

  return meData;
}

export async function authorization() {
  if (detectedBridgePlatform || process.env.NODE_ENV === "development") {
    store.dispatch('auth/error', []);
    return await getMe().then(async (meData) => {
      store.dispatch('user/set', meData);
      const token =  await authViaBridge(meData);

      setAuthToken(token);
      return token;
    }).catch((err) => {
      store.dispatch('auth/error', [
        ...store.state.auth.errorText,
        err,
      ]);
      throw Error(err);
    });
  }
}