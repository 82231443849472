import baseApi from "@/helpers/baseApi";
import { myVideoApi } from "@/helpers/myVideoApi";

const state = () => ({
  isOpenStories: false,
  favourite: {
    requested: false,
    items: [],
  },
  stream: {
    curStreamParams: null,
    curStream: null,
  },
  menu: {
    requested: false,
    items: [],
    filteredItems: [],
    searchValue: '',
  },
  slider: {
    requested: false,
    items: [],
  },
  is_onboarding: window.localStorage.getItem("onboarded") !== "true",
  favorites_streams: [],
  cameras_info: {
    enabled: 0,
    disabled: 0,
    overall: 0,
  },
  page_amount: 1,
  loading: false,
});

const mutations = {
  'set_is_open_stories'(state, payload){
    state.isOpenStories = payload;
  },

  setStream(state, payload) {
    state.stream = {
      curStreamParams: payload.curStreamParams,
      curStream: payload.curStream,
    };
  },

  clearStream(state) {
    state.stream = {
      curStreamParams: null,
      curStream: null,
    };
  },

  setMenuItems(state, payload){
    state.menu = {
      ...state.menu,
      items: payload,
    };
  },

  setMenuSearchValue(state, payload){
    state.menu = {
      ...state.menu,
      searchValue: payload,
    };
  },

  setFilteredMenuItems(state, payload){
    state.menu = {
      ...state.menu,
      filteredItems: payload,
    };
  },

  setMenuRequested(state){
    state.menu = {
      ...state.menu,
      requested: true,
    };
  },

  setFavouriteItems(state, payload){
    state.favourite = {
      ...state.favourite,
      items: payload,
    };
  },

  setFavouriteRequested(state){
    state.favourite = {
      ...state.favourite,
      requested: true,
    };
  },

  'add_favourite'(state, payload){
    // TODO
    state.favourite = {
      ...state.favourite,
      items: [
        ...state.favourite.items,
        payload,
      ],
    };
  },

  'remove_favourite'(state, id){
    state.favourite = {
      ...state.favourite,
      items: state.favourite.items.filter((fav) => {
        return fav.id != id;
      }),
    };
  },

  setSliderItems(state, payload){
    state.slider = {
      ...state.slider,
      items: payload,
    };
  },

  setSliderRequested(state){
    state.slider = {
      ...state.slider,
      requested: true,
    };
  },

  'set_is_onboarding'(state, value){
    state.is_onboarding = value;
  },

  setIsFavoriteStream(state, payload){
    state.favorites_streams = [...state.favorites_streams, ...payload];
  },

  addFavoriteStream(state, payload) {
    state.favorites_streams = [payload, ...state.favorites_streams];
  },

  removeFavoriteStream(state, payload) {
    state.favorites_streams = state.favorites_streams.filter((stream) => stream.camera_id !== payload);
  },

  loading(state, payload){
    state.loading = payload;
  },

  setCamerasInfo(state, payload) {
    state.cameras_info = {
      enabled: payload.number_active_cameras,
      disabled: payload.number_inactive_cameras,
      overall: payload.total_cameras,
    };
  },

  setPageAmount(state, payload) {
    state.page_amount = payload;
  },
};

const actions = {
  initialize({ dispatch }){
    return new Promise((resolve, reject) => {
      dispatch('getMenu')
        .then(() => {
          Promise.allSettled([
            dispatch('getFavourites'),
            dispatch('getSlider'),
          ]).then(resolve);
        })
        .catch(reject);
    });
  },

  setIsOpenStories({commit}, openStoriesValue){
    commit('set_is_open_stories', openStoriesValue);
  },

  async getMenu({ commit, rootState, dispatch  }){
    try {
      const res = await baseApi('api/v2/menu/navs/');
      if (res?.data) {
        commit('setMenuItems', res.data);
        commit('setMenuRequested');
      } else {
        throw Error('no menu items in response');
      }
    } catch(err) {
      dispatch('auth/error', [...rootState.auth.errorText, err], { root: true });
      throw Error(err);
    }
  },

  async getFilteredMenu({ commit }, filter){
    try {
      commit('loading', true);
      const res = await baseApi(`api/v2/menu/navs/?search=${filter}`);
      if (res?.data && filter) {
        commit('setFilteredMenuItems', res.data);
      } else if (res.data && !filter){
        commit('setFilteredMenuItems', []);
      }
      } finally {
        commit('loading', false);
      }
  },

  async getFavourites({ commit }){
    try {
      const res = await baseApi('api/menu/favorites/');
      if (res.data) {
        commit('setFavouriteItems', res.data);
      } else {
        throw Error('no favourite items in response');
      }
      } finally {
        commit('setFavouriteRequested');
      }
  },

  async getSlider({ commit }){
    try {
      const res = await baseApi('api/menu/banners/');
      if (res?.data) {
        commit('setSliderItems', res.data);
      } else {
        throw Error('no banner items in response');
      }
    }
    finally {
      commit('setSliderRequested');
    }
  },

  setMenuSearchValue({ commit }, payload){
    commit('setMenuSearchValue', payload);
  },

  async setOnboarded({ commit }){
    window.localStorage.setItem("onboarded", "true");
    commit('set_is_onboarding', false);
  },

  async setNotOnboarded({ commit }){
    window.localStorage.removeItem('onboarded');
    commit('set_is_onboarding', true);
  },

  loadingChange({ commit }, loading) {
    commit('loading', loading);
  },

  async addFavourite({ commit }, id){
    const res = await baseApi.post(`api/menu/favorites/`, { menu_item: id });
    if (res.data) {
      commit('add_favourite', res.data);
    }
  },

  async removeFavourite({ state, commit }, menu_item_id){
    let id = state.favourite.items.find((fav_item) =>
      (fav_item.menu_item == menu_item_id))?.id;

    await baseApi.delete(`api/menu/favorites/${id}/`);
    commit('remove_favourite', id);
  },

  async setFavoriteStream({  commit }, stream) {
    commit('loading', true);
    await myVideoApi
      .put(`/v2/cameras/favorites/${stream.camera_id}/`)
      .then(() => {
        commit('addFavoriteStream', stream);
      }).finally(()=> { commit('loading', false); });
  },

  async removeFavoriteStream({ commit}, camera_id) {
    commit('loading', true);
    await myVideoApi.delete(`/v2/cameras/favorites/${camera_id}/`).then(() => {
     commit('removeFavoriteStream', camera_id);
    }).finally(()=> { commit('loading', false); });
  },

  async fetchFavoriteStream({ commit }, page) {
    commit('loading', true);
    await
      myVideoApi(`/v2/cameras/favorites/?page=${page}`)
        .then((res) => {
          commit('setIsFavoriteStream', res.data.cameras);
          commit('setCamerasInfo', res.data);
          commit('setPageAmount', res.data.page_amount);
        })
        .finally(() => {
          commit('loading', false);
        });
  },
  setCameraStream({commit}, stream) {
    commit('setStream', stream);
  },
  clearCameraStream({commit}) {
    commit('clearStream');
  },
};

const getters = {
  // APP STORIES
  is_open_stories: (state) => {
    return state.isOpenStories;
  },
  // MENU
  menu_list: (state) => {
    return state.menu.items;
  },
  slider_list: (state) => {
    return state.slider.items;
  },
  filterd_menu_list: (state) => {
    return state.menu.filteredItems;
  },
  menu_is_requested: (state) => {
    return state.menu.requested;
  },
  slider_is_requested: (state) => {
    return state.slider.requested;
  },
  favourite_is_requested: (state) => {
    return state.favourite.requested;
  },
  menu_parents: (state) => (
    state.menu.items
      ?.filter(el => el.parent === null && !el.burger)
      .sort((el1,el2) => el1.priority - el2.priority)
  ),
  // FAVOURITE
  'is_favourite': (state) => (id) => (
    state.favourite.requested &&
    state.favourite.items.some(item => item.menu_item === Number(id))
  ),
  stream_is_favorite: (state) => (camera_id) => {
    return state.favorites_streams.some(stream => stream.camera_id === camera_id);
  },
  // CAMERA STREAM
  camera_stream: (state) =>  { return state.stream; },
};

export const mainPage = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};