import baseApi from "@/helpers/baseApi.js";
import marketplaceApi from "@/helpers/marketplaceApi.js";
import merchApi from "@/helpers/merchApi.js";

import wayApi from "@/helpers/wayApi.js";
import myApi from '@/helpers/myApi.js';
import myVideoApi from '@/helpers/myVideoApi';

const setAuthToken = (token) => {
  baseApi.defaults.headers['Authorization'] = 'Token '+ token;
  wayApi.defaults.headers['Authorization'] = 'Token '+ token;
  myVideoApi.defaults.headers['Authorization'] = 'Token '+ token;
  // TODO - проверить нужен ли myApi
  myApi.defaults.headers['Authorization'] = 'Token ' + token;
  if (process.env.NODE_ENV === "development") {
    marketplaceApi.defaults.headers['Authorization'] = 'Token 7866178473b210e6f3616831c3ae06e4e22993e9';
    merchApi.defaults.headers['Authorization'] = 'Token a1308753ea00b58b223b91049544035cae6154d6';
  } else {
    merchApi.defaults.headers['Authorization'] = `Token ${token}`;
    marketplaceApi.defaults.headers['Authorization'] = 'Token '+ token;
  }
};

const clearAuthToken = () => {
  delete baseApi.defaults.headers['Authorization'];
  delete wayApi.defaults.headers['Authorization'];
  delete myVideoApi.defaults.headers['Authorization'];
  delete myApi.defaults.headers['Authorization'];

  if (process.env.NODE_ENV === "production") {
    delete merchApi.defaults.headers['Authorization'];
    delete marketplaceApi.defaults.headers['Authorization'];
  }
};


export {
  setAuthToken,
  clearAuthToken,
};
