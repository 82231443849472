var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loader-screen",class:{
    /* 'loader-screen--dark': theme === 'dark', */
    'loader-screen--light': true
  }},[_c('div',{staticClass:"loader-screen__logo-container"},[_c('BaseIcon',{staticClass:"logo--dark",attrs:{"name":"erg"}})],1),(_vm.errorShow)?_c('div',{staticClass:"loader-screen__error"},[(!_vm.reloadClicked)?[_c('p',[_vm._v(_vm._s(_vm.$translate('app-loader_error_title')))]),(_vm.errorText.length)?_vm._l((_vm.errorText),function(text,k){return _c('p',{key:k},[_vm._v(" "+_vm._s(text)+" ")])}):_vm._e()]:_vm._e(),_c('VBtn',{staticClass:"mt-16px",attrs:{"disabled":_vm.reloadClicked,"outlined":""},on:{"click":_vm.handleReloadClick}},[_vm._v(" "+_vm._s(_vm.$translate('app-loader_error_reload'))+" "),_c('img',{staticClass:"ml-4px",class:{
          'reload-icon--rotating': _vm.reloadClicked
        },attrs:{"src":require("@/assets/svg/nav-menu_refresh.svg")}})])],2):_vm._e(),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loader-screen__bottom"},[_c('p',{staticClass:"text-center small"},[_vm._v(" from ")]),_c('h5',{staticClass:"text-center"},[_vm._v(" BTS ")])])
}]

export { render, staticRenderFns }