import { NOT_IN_STORAGE } from "@/config/constants";
import { setStorageItem, getStorageItem } from "@/helpers/bridge";
import { clearAuthToken } from "@/helpers/setAuthToken";

async function getTokenFromStorage() {
  try {
    const token = await getStorageItem('token');

    return token;
  } catch (err) {
    console.log('err', err);
     if (err.message === NOT_IN_STORAGE) {
      return '';
     }
  }
}

const state = () => ({
  token: getTokenFromStorage() || '',
  errorText: [],
});

const mutations = {
  setToken(state, payload){
    state.token = payload;
  },
  clearToken(state){
    state.token = null;
  },
  setError(state, payload) {
    state.errorText = payload;
  },
};

const actions = {
  login({ commit }, payload){
    commit('setToken', payload);
    setStorageItem('token', payload);
  },
  logOut({ commit }){
    commit('clearToken');
    clearAuthToken();
    localStorage.removeItem(`${process.env.VUE_APP_STORAGE_PREFIX}_token`);
  },
  error({ commit }, payload) {
    commit('setError', payload);
  },
  errorClear({ commit }) {
    commit('setError', []);
  },
};

const getters = {
  isLoggedIn: (state) => !!state.token,
  getToken: (state) => state.token,
  errorText: (state) => state.errorText,
  errorShow: (state) => !!state.errorText.length,
};

export const auth = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};