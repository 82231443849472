import axios from "axios";
import { authorization } from "@/services/auth";
import store from "@/store/index";

const baseApiInstance = axios.create({
  baseURL: (process.env.VUE_APP_API_HOST || ''),
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Platform': 'mobile',
  },
  params: {
    format: 'json',
  },
});

baseApiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401 && !error.config._isRetry) {

      error.config._isRetry = true;
      store.dispatch('auth/logOut');
      await authorization();

      error.config.headers['Authorization'] = baseApiInstance.defaults.headers['Authorization'];
      return baseApiInstance(error.config);
    }
    return Promise.reject(error);
  },
);

export default baseApiInstance;
export { baseApiInstance as baseApi };