import { render, staticRenderFns } from "./AppLoaderScreen.vue.js?vue&type=template&id=545e576a!=!../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./AppLoaderScreen.vue?vue&type=template&id=545e576a"
import script from "./AppLoaderScreen.vue.js?vue&type=script&lang=js!=!../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./AppLoaderScreen.vue?vue&type=script&lang=js"
export * from "./AppLoaderScreen.vue.js?vue&type=script&lang=js!=!../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./AppLoaderScreen.vue?vue&type=script&lang=js"
import style0 from "./AppLoaderScreen.vue.scss?vue&type=style&index=0&id=545e576a&prod&lang=scss!=!../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./AppLoaderScreen.vue?vue&type=style&index=0&id=545e576a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports